import React from 'react';
import { KoalaSizes } from 'koala/shared/global';
import { colors, spacing } from 'koala/shared/styles';
import KoalaIcon from '../Icons';
import { useState } from 'react';
import styled from 'styled-components';
import Icons from '../../shared/images/icons/IconList';

const CustomRadioField = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px;
  gap: ${spacing.half};
`;

const CustomRadioButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 3px;
  border: unset;
  color: ${(props) => (props.isSelected ? colors.B50 : colors.N40)};
  background-color: ${(props) => (props.isSelected ? colors.B5 : 'unset')};

  :hover {
    background-color: ${colors.N5};
  }
`;

export interface IconToggleProps {
  icons: string[];
  defaultIcon?: string;
  /** Can either be KoalaSizes value or a number for the rem value. Default is medium */
  iconSize?: (typeof KoalaSizes)[number] | number;
  handleToggle: (icon: string) => void;

  className?: string;
  htmlFor?: string;
  style?: React.CSSProperties;
}

const KoalaIconToggle: React.FunctionComponent<IconToggleProps> = (props: IconToggleProps) => {
  const { icons, iconSize, defaultIcon, className, handleToggle } = props;
  const [selectedIcon, setSelectedIcon] = useState(defaultIcon ?? icons[0]);

  let iconClass = className ? className : '';

  if (typeof iconSize === 'string') {
    iconClass += ` icon--${iconSize}`;
  } else if (typeof iconSize === 'number') {
    iconClass += ` icon--custom-size`;
  }

  const handleToggleChange = (icon: string) => {
    setSelectedIcon(icon);
    handleToggle(icon);
  };

  return (
    <CustomRadioField>
      {icons.map((icon) => {
        if (Icons[icon] === undefined) return null;
        const isSelected = selectedIcon === icon;

        return (
          <CustomRadioButton onClick={(e) => handleToggleChange(icon)} isSelected={isSelected}>
            <KoalaIcon
              iconName={icon}
              iconSize="small"
              iconAppearance={isSelected ? 'subtle' : ''}
              className={iconClass}
            />
          </CustomRadioButton>
        );
      })}
    </CustomRadioField>
  );
};

KoalaIconToggle.defaultProps = {
  iconSize: 'medium',
};

export default KoalaIconToggle;
