import ConfidenceBar from 'components/ConfidenceBar';
import ProgressBarV2 from 'components/ProgressBarV2';
import KoalaAvatar from 'koala/components/Avatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'theme';
import { Membership, Workspace } from 'types';
import { CustomTermKey, translate } from 'utils/customTermUtils';

const CardContainer = styled.div`
  width: 280px;
  border: 1px solid ${theme.colors.N10};
  border-radius: 4px;

  :hover {
    background: ${theme.colors.N3};
    cursor: pointer;
  }
`;

const PersonSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.N10};
`;
const MetricSection = styled.div`
  padding: ${theme.spacing.x2};
  display: flex;
  gap: ${theme.spacing.x1};
`;
const MetricBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
`;
const MembershipDetails = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
`;

interface Props {
  membership: Membership;
  workspace: Workspace;
}

function PeopleCard(props: Props) {
  const { membership, workspace } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const path = `/${workspace.slug}/people/${membership.id}`;

  return (
    <CardContainer onClick={() => history.push(path)}>
      <PersonSection>
        <KoalaAvatar membership={membership} tooltipType="none" size="large" />
        <MembershipDetails>{membership.cached_fullname ?? membership.cached_email}</MembershipDetails>
      </PersonSection>
      <MetricSection>
        <MetricBlock>
          <small className="subtle">{t('shared.progress')}</small>
          <ProgressBarV2
            percentage={membership.outcome_progress_prct * 100}
            totalCount={membership.total_outcomes_count}
            label={`${Math.round(membership.outcome_progress_prct * 100)}%`}
          />
        </MetricBlock>
        <MetricBlock>
          <small className="subtle">{translate(workspace, CustomTermKey.INITIATIVE, 2)}</small>
          <ProgressBarV2
            percentage={membership.initiative_progress_prct * 100}
            totalCount={membership.total_initiatives_count}
            label={`${membership.closed_initiatives_count}/${membership.total_initiatives_count}`}
          />
        </MetricBlock>
        <MetricBlock>
          <small className="subtle">{t('shared.confidence.label')}</small>
          <ConfidenceBar
            totalCount={membership.total_outcomes_count}
            redCount={membership.red_outcomes_count}
            yellowCount={membership.yellow_outcomes_count}
            greenCount={membership.green_outcomes_count}
            greyCount={membership.grey_outcomes_count}
            ncs={Math.round(membership.ncs)}
          />
        </MetricBlock>
      </MetricSection>
    </CardContainer>
  );
}

export default React.memo(PeopleCard);
