import { fetchPlans } from 'api/remote';
import queryKeys from 'config/queryKeys';
import React, { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Plan, Workspace } from 'types';
import parse from 'parse-link-header';
import theme from 'theme';
import styled from 'styled-components';

// Routes

import EmptyStatePanel from 'components/EmptyStatePanel';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import { useTranslation } from 'react-i18next';
import PlanViewCards from './PlanViewCards';
import PlanViewList from './PlanViewList';
import { AxiosResponse } from 'axios';
import { DEFAULT_TEAM_PLAN_CARDS } from 'config/constants';
import KoalaIconToggle from 'koala/components/IconToggle';

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.spacing.x2};
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x2};
`;

const PlanHeader = styled.div`
  display: flex;
  align-items: center;
`;
const PlanSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x1};
  padding-right: ${theme.spacing.x2};
  border-right: 1px solid ${theme.colors.N20};
`;
const ViewSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.x1};
  padding-left: ${theme.spacing.x2};
`;

interface Props {
  workspace: Workspace;
  teamId: string;
}
function PlansListSearch(props: Props) {
  const { workspace, teamId } = props;
  let plans: AxiosResponse<Plan[]>[] = []; // Initialize the plans as an empty array
  const { t } = useTranslation();

  const storedDefaultViewCards: boolean = localStorage.getItem(DEFAULT_TEAM_PLAN_CARDS)
    ? localStorage.getItem(DEFAULT_TEAM_PLAN_CARDS) === 'true'
    : false;

  const [showCards, setShowCards] = useState(storedDefaultViewCards);

  useEffect(() => {
    localStorage.setItem(DEFAULT_TEAM_PLAN_CARDS, JSON.stringify(showCards));
  }, [showCards]);

  const getNextPage = (response: any) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  const filter = { team_id: teamId };
  const params = {
    filter,
  };
  const {
    isFetchingMore,
    data: plansResponse,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery([queryKeys.plans, workspace.slug, params], fetchPlans, {
    getFetchMore: (lastGroup, allGroups) => {
      return getNextPage(lastGroup);
    },
  });
  let totalResults = 0;
  if (plansResponse) {
    totalResults = plansResponse[0] ? parseInt(plansResponse[0]['headers']['x-total']) : 0;
    plans = plansResponse;
  }
  const handleViewChange = (value: string) => {
    setShowCards(value === 'cards');
  };
  // This checks if we have zero results for plans.
  // Could definitely be more elegant.
  const noResultsFound = plans.length > 0 && plans[0].data.length === 0;

  return (
    <PlanContainer>
      <PlanHeader>
        <PlanSection>
          <h3>{t('workspaceTeams.teamMember.plans')}</h3>
          <KoalaTextBadge edge="circle" variant="neutral-light">
            {totalResults}
          </KoalaTextBadge>
        </PlanSection>
        <ViewSection>
          <span>View:</span>
          <KoalaIconToggle
            icons={['menu', 'cards']}
            defaultIcon={showCards ? 'cards' : 'menu'}
            handleToggle={handleViewChange}
          />
        </ViewSection>
      </PlanHeader>
      {noResultsFound && <EmptyStatePanel>{t('workspaceTeams.teamMember.noPlans')}</EmptyStatePanel>}

      {!noResultsFound && showCards && <PlanViewCards plans={plans} workspace={workspace} />}
      {!noResultsFound && !showCards && <PlanViewList plans={plans} workspace={workspace} />}
      {canFetchMore && (
        <LoadMore>
          <KoalaButton
            onClick={() => fetchMore()}
            loading={!!isFetchingMore}
            disabled={!!isFetchingMore}
            appearance="secondary"
          >
            {t('shared.loadMore')}
          </KoalaButton>
        </LoadMore>
      )}
    </PlanContainer>
  );
}

export default PlansListSearch;
