import React, { memo, useState } from 'react';
import { useQuery } from 'react-query';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import { Link, useParams, useHistory } from 'react-router-dom';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';

import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import * as routes from 'routes';

import PlanIconLabel from 'components/PlanIconLabel';
import KoalaButton from 'koala/components/Button';
import KoalaTextBadge from 'koala/components/TextBadge';
import OutcomeValue from 'components/OutcomeValue';
import KoalaIcon from 'koala/components/Icons';
import KoalaCircularProgress, { ProgressData } from 'koala/components/CircularProgress';
import ReactTooltip from 'react-tooltip';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import Initiatives from './Initiatives';
import { shallowEqual, useSelector } from 'react-redux';
import KoalaIconButton from 'koala/components/IconButton';
import { Outcome, Relationship } from 'types';
import queryKeys from 'config/queryKeys';
import { nodeDefaults } from 'utils/mapUtils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing.x1};
  min-height: 35rem;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${theme.spacing.x2};
  width: 40rem;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  gap: ${theme.spacing.x1};

  &:hover {
    background-color: ${theme.colors.N3};
    cursor: pointer;
  }
`;
const ValueContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.x2};
`;

const PlanTitle = styled.div``;

const Title = styled.div`
  font-weight: 600;
  font-size: 1.6rem;
`;
const ProgressContainer = styled.div``;
const PendingContainer = styled.div`
  padding-right: ${theme.spacing.x1};
  display: inline-block;
`;

const PendingIcon = styled.div`
  background-color: ${theme.colors.R50};
  height: 8px;
  width: 8px;
  border-radius: 4px;
`;

const DateContainer = styled.div`
  display: flex;
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const MetaContentLine = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  gap: ${theme.spacing.x1};
  &:hover {
    background-color: ${theme.colors.N10};
  }
  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
  }
  &.expanded {
    .icon-btn--square {
      transform: rotate(90deg);
    }
  }
  margin-top: ${theme.spacing.x1};
  padding: ${theme.spacing.x1} 0;
`;

function OutcomeNode(outcomeNode: NodeProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { data } = outcomeNode;
  const { outcome, expanded, toggleNodeExpand, isExpandable } = data;
  const { plan } = outcome;
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [showInitiatives, setShowInitiatives] = useState(false);
  const [dependentOutcomes, setDependentOutcomes] = useState<Outcome[]>([]);

  const currentWorkspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // We use addNodes and addEdges to add to the flow graph state
  const { addNodes, addEdges } = useReactFlow();

  // Get the outcomes
  const queryKey = [queryKeys.relationships, outcome.id, 'outcomes'];
  const staleTime = 0;
  const { isLoading } = useQuery(queryKey, remoteApi.fetchRelationshipsForOutcome, {
    staleTime,
    onSuccess: (response: any) => {
      const outcomeRelationships: Relationship[] = response.data;
      const dependents: Outcome[] = outcomeRelationships
        .filter(
          (r) => r.target_id === outcome.id && r.relationship_type === 'contributes_to' && r.source_type === 'Outcome',
        )
        .map((r) => r.source as Outcome);
      setDependentOutcomes(dependents);

      const newNodesToAdd = dependents.map((newOutcome) => ({
        id: newOutcome.nano_slug,
        type: 'outcome',
        data: { outcome: newOutcome, expanded: false, toggleNodeExpand, isExpandable: true },
        ...nodeDefaults,
      }));

      // Create the corresponding edges
      const newEdgesToAdd = dependents.map((newOutcome) => ({
        id: `${outcome.nano_slug} -> ${newOutcome.nano_slug}`,
        target: newOutcome.nano_slug,
        source: outcome.nano_slug,
        targetHandle: 'in',
        sourceHandle: 'out',
        animated: true,
        type: 'step',
        pathOptions: { offset: 5 },
        style: {
          strokeWidth: 2,
        },
      }));

      addNodes(newNodesToAdd);
      addEdges(newEdgesToAdd);
    },
  });

  const hashPath = `#outcome:${outcome.nano_slug}:show`;
  const handleOutcomeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(hashPath);
  };

  const toggleNodeExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
    toggleNodeExpand(event, outcomeNode);
  };

  // Only show the expand button if there are children and the node is not expanded
  const hasChildren = dependentOutcomes.length > 0 && !isLoading;

  // Checkin logic -- preparing data to be displayed in the component
  const checkin = outcome.current_checkin;

  const currentConfidence = checkin ? checkin.confidence : 'grey';
  const confidenceLetter = checkinUtils.confidenceToColorLetter(currentConfidence);
  const progressPrct = outcome.outcome_progress_prct * 100;
  const progressData: ProgressData = {
    progressPercentage: progressPrct,
    colorType: confidenceLetter,
    centerLabel: '',
  };

  const hasTarget = outcomeUtils.hasTarget(outcome);

  // Plan link
  let routeTarget = '';
  let planRoute = '';
  if (plan) {
    routeTarget = plan.state === 'published' ? routes.WORKSPACE_PLAN_TRACK_ROUTE : routes.WORKSPACE_PLAN_WRITE_ROUTE;
    planRoute = routeTarget.replace(':workspaceSlug', workspaceSlug).replace(':planId', plan.nano_slug);
  }

  const expandedClass = (itemState: boolean) => {
    if (itemState) {
      return 'expanded';
    }
    return '';
  };

  const handleToggleInitiatives = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInitiatives(!showInitiatives);
  };

  return (
    <Wrapper>
      <Container onClick={handleOutcomeClick}>
        <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
        {plan && (
          <PlanTitle>
            <Link to={planRoute} onClick={(e) => e.stopPropagation()}>
              <PlanIconLabel plan={plan} />
            </Link>
          </PlanTitle>
        )}
        <Title>{outcome.title}</Title>
        <ValueContainer>
          <div style={{ flex: 1 }}>
            <OutcomeValue outcome={outcome} />
          </div>
          {hasTarget && (
            <ProgressContainer>
              <KoalaCircularProgress data={progressData} size="medium" dataFor={`percentage-${outcome.nano_slug}`} />
              <ReactTooltip
                place="bottom"
                type="dark"
                className="tooltip"
                effect="solid"
                id={`percentage-${outcome.nano_slug}`}
              >
                {t('shared.percentageComplete', { percentage: progressPrct > 0 ? progressPrct.toFixed(0) : 0 })}
              </ReactTooltip>
            </ProgressContainer>
          )}
        </ValueContainer>
        <DateContainer>
          {checkin && (
            <KoalaTextBadge variant="neutral-light" edge="circle" maxWidth="20rem">
              {outcome.is_pending_checkin && (
                <PendingContainer>
                  <PendingIcon />
                </PendingContainer>
              )}
              {t('shared.updatedDate', {
                date: formatDistanceToNowLocale(checkin.checkin_date, i18n, true, true),
              })}
            </KoalaTextBadge>
          )}
          {!checkin && (
            <KoalaTextBadge variant="neutral-light" edge="circle" maxWidth="20rem">
              {outcome.is_pending_checkin && (
                <PendingContainer>
                  <PendingIcon />
                </PendingContainer>
              )}
              {t('workspacePlan.map.pendingUpdate')}
            </KoalaTextBadge>
          )}
        </DateContainer>
        <MetaContentLine className={expandedClass(showInitiatives)} onClick={handleToggleInitiatives}>
          <KoalaIconButton iconName="rightChevron" size="xsmall" edge="square" onClick={handleToggleInitiatives} />
          <h4>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</h4>
        </MetaContentLine>
        {showInitiatives && <Initiatives outcome={outcome} hideClosedInitiatives={false} />}

        <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
      </Container>
      {hasChildren && !expanded && isExpandable && (
        <div>
          <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
            <KoalaIcon iconName="downChevron" style={{ transform: 'rotate(-90deg)' }} />
            {t('workspacePlan.map.expand')}
          </KoalaButton>
        </div>
      )}
      {hasChildren && expanded && isExpandable && (
        <div>
          <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
            <KoalaIcon iconName="minus" />
            {t('workspacePlan.map.collapse')}
          </KoalaButton>
        </div>
      )}
    </Wrapper>
  );
}

export default memo(OutcomeNode);
