import React, { useState } from 'react';
import { Fragment } from 'react';
import * as TabilityTypes from 'types';
import { Helmet } from 'react-helmet';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { ChromeContent } from 'components/Chrome';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import { fetchPlans } from 'api/remote';
import { Edge, Node, ReactFlowProvider } from 'reactflow';
import Map from './Map';
import UpgradeRequiredLegacy from './UpgradeRequiredLegacy';
import UpgradeRequiredV4 from './UpgradeRequiredV4';
import { Base64 } from 'js-base64';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as routes from 'routes';
import { addDays, addMonths, endOfQuarter, startOfQuarter, subDays, subMonths } from 'date-fns';
import * as remoteApi from 'api/remote';
import MapFilters from './MapFilters';
import KoalaTextButton from 'koala/components/TextButton';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import ViewBlock from './ViewBlock';
import ShareButton from 'components/ShareButton';
import { edgeDefaultsDown, nodeDefaults } from 'utils/mapUtils';

const UpgradeModal = styled.div`
  position: fixed;
  left: 50%;
  max-height: 25rem;
  z-index: 80;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.half};
  align-items: start;
`;

const Actions = styled.div`
  display: flex;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceMapV2(props: Props) {
  const { workspace } = props;
  const { slug } = workspace;
  const { t } = useTranslation();
  const [initialElements, setInitialElements] = useState<{ edges: Edge[]; nodes: Node[] }>({ nodes: [], edges: [] });
  const [hasFetchedPlans, setHasFetchedPlans] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const storageKey = `__tbty_${workspace.slug}_expanded_plans`;
  const storedExpandedPlansValue = localStorage.getItem(storageKey);
  const storedExpandedPlans = storedExpandedPlansValue ? JSON.parse(storedExpandedPlansValue) : {};

  const storageKeyPlanOutcomes = `__tbty_${workspace.slug}_expanded_plan_outcomes`;
  const storedExpandedPlanOutcomesValue = localStorage.getItem(storageKeyPlanOutcomes);
  const storedExpandedPlanOutcomes = storedExpandedPlanOutcomesValue ? JSON.parse(storedExpandedPlanOutcomesValue) : {};

  const { workspaceSlug, filter, segmentId } = useParams<{
    workspaceSlug: string;
    filter: string;
    segmentId: string;
  }>();

  const isSegment = segmentId ? true : false;
  const { data: segmentResponse } = useQuery([queryKeys.segments, segmentId], remoteApi.fetchSegmentDetails, {
    enabled: isSegment,
  });
  const segment: TabilityTypes.Segment = segmentResponse ? segmentResponse.data : null;

  let filterPath = segmentId
    ? routes.WORKSPACE_MAP_V2_SEGMENT_ROUTE_WITH_FILTERS.replace(':segmentId', segmentId)
    : routes.WORKSPACE_MAP_V2_ROUTE_WITH_FILTERS;

  const updateURL = (filterHash: { [key: string]: any }) => {
    const encodedHash = Base64.encode(JSON.stringify(filterHash));
    history.push(filterPath.replace(':workspaceSlug', workspaceSlug).replace(':filter', encodedHash));
  };

  let customFilterHash: { [key: string]: any } = {};
  if (filter) {
    customFilterHash = JSON.parse(Base64.decode(filter));
  } else {
    updateURL({ status: 'planning' });
  }

  // Ignore upgrade check for workspace created before December 15th;
  const hasEssentialsSubscription =
    workspaceUtils.hasEssentialsSubscription(workspace) || workspace.pricing_version >= 2;

  // Check the upgrade required flag
  let upgradeRequired = false;

  // Only show upgrade if on the old v4 free plan
  if (workspace.pricing_version === 4 && workspace.billing_subscription_plan === 'tability-v4-free') {
    upgradeRequired = !workspace.billing_has_done_marketing;
  }

  if (workspace.pricing_version < 4) {
    upgradeRequired = !hasEssentialsSubscription;
  }

  const filterParams: { [key: string]: any } = {};

  //Status
  if (customFilterHash.status) {
    filterParams['status'] = customFilterHash.status;
  }

  // Teams
  if (customFilterHash.team && customFilterHash.team.length > 0) {
    filterParams['team_id'] = customFilterHash.team.map((m: any) => m.value);
  }
  // Timeline
  let start_at, finish_at, refDate;
  if (customFilterHash.timeline) {
    switch (customFilterHash.timeline) {
      case 'current_quarter':
        refDate = Date.now();
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'last_quarter':
        refDate = subMonths(Date.now(), 3);
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'next_quarter':
        refDate = addMonths(Date.now(), 3);
        start_at = subDays(startOfQuarter(refDate), 1).toISOString();
        finish_at = addDays(endOfQuarter(refDate), 1).toISOString();
        break;
      case 'custom':
        start_at = customFilterHash.start_at;
        finish_at = customFilterHash.finish_at;
        break;
      default:
        start_at = null;
        finish_at = null;
    }
  }
  if (start_at) {
    filterParams['start_after'] = start_at;
  }
  if (finish_at) {
    filterParams['finish_before'] = finish_at;
  }

  // Plan status
  let is_in_progress, is_draft;
  if (customFilterHash.plan_status) {
    switch (customFilterHash.plan_status) {
      case 'is_in_progress':
        is_in_progress = true;
        break;
      case 'is_draft':
        is_draft = true;
        break;
    }
  }
  if (is_in_progress) {
    filterParams['is_in_progress'] = is_in_progress;
  }
  if (is_draft) {
    filterParams['is_draft'] = is_draft;
  }

  const params = {
    limit: 200,
    filter: filterParams,
  };

  useQuery([queryKeys.plans, slug, params], fetchPlans, {
    onSuccess: (response) => {
      // Create the plans mapping
      const plans: TabilityTypes.Plan[] = response.data;

      const nodes: Node[] = [
        {
          id: 'workspace',
          data: { workspace, expanded: true },
          type: 'workspace',
          ...nodeDefaults,
        },
      ];
      const edges: Edge[] = [];
      plans.forEach((plan) => {
        const hasParent = plan.parent_id;
        if (hasParent) {
          //  if plan has parent, add edge
          edges.push({
            id: `${plan.parent_id}:${plan.id}`,
            target: plan.id,
            source: plan.parent_id,
            ...edgeDefaultsDown,
          });
        } else {
          // top level plan, add edge from workspace to plan
          edges.push({
            id: `workspace:${plan.id}`,
            target: plan.id,
            source: 'workspace',
            ...edgeDefaultsDown,
          });
        }
        nodes.push({
          id: plan.id,
          type: 'plan',
          data: {
            expanded: !!storedExpandedPlans[plan.id],
            plan,
            outcomesExpanded: !!storedExpandedPlanOutcomes[plan.id],
          },
          ...nodeDefaults,
        });
      });
      setInitialElements({ edges, nodes });
      setHasFetchedPlans(true);
    },
  });

  const showCreateSegment = () => {
    dispatch(setGlobalModalContent(`segment:${filter}:create.map`));
  };

  const handleShare = () => {
    dispatch(setGlobalModalContent('workspace:map:share'));
  };

  const link = `https://${process.env.REACT_APP_DOMAIN}${location.pathname}`;

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceMap.title')} | Tability
        </title>
      </Helmet>
      <ChromeContent>
        {upgradeRequired && (
          <UpgradeModal>
            {workspace.pricing_version === 4 && <UpgradeRequiredV4 />}
            {workspace.pricing_version < 4 && <UpgradeRequiredLegacy />}
          </UpgradeModal>
        )}
        <Content>
          <HeaderContainer>
            <FilterContainer>
              <MapFilters customFilterHash={customFilterHash} updateURL={updateURL} />
              {false && (
                <>
                  {segment && <ViewBlock segment={segment} filterHash={filter} workspaceSlug={workspaceSlug} />}
                  {!segment && (
                    <KoalaTextButton appearance="subtle" className="segment-save-button" onClick={showCreateSegment}>
                      <span>{t('workspaceMap.saveView')}</span>
                    </KoalaTextButton>
                  )}
                </>
              )}
            </FilterContainer>
            <Actions>
              <ShareButton id="strategy-map" link={link} shareCallback={handleShare} />
            </Actions>
          </HeaderContainer>
          {hasFetchedPlans && (
            <ReactFlowProvider>
              <Map initialEdges={initialElements.edges} initialNodes={initialElements.nodes} />
            </ReactFlowProvider>
          )}
        </Content>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceMapV2;
