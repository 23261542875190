import ConfidenceBar from 'components/ConfidenceBar';
import ProgressBarV2 from 'components/ProgressBarV2';
import RoleBadge from 'components/RoleBadge';
import KoalaAvatar from 'koala/components/Avatar';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Membership, Workspace } from 'types';
import { ListingLine } from '.';

interface Props {
  membership: Membership;
  workspace: Workspace;
}

function PeopleLine(props: Props) {
  const { membership, workspace } = props;
  const history = useHistory();
  const { user } = membership;
  const name = user.fullname || user.email;
  const path = `/${workspace.slug}/people/${membership.id}`;

  return (
    <ListingLine key={membership.id} onClick={() => history.push(path)}>
      <div>
        <KoalaAvatar membership={membership} size={3.2} tooltipType="card" />
        <Link to={path}>{name}</Link>
      </div>
      <div>
        <RoleBadge role={membership.role} />
      </div>
      <div className="outcomes">
        <ProgressBarV2
          percentage={membership.outcome_progress_prct * 100}
          totalCount={membership.total_outcomes_count}
          label={`${Math.round(membership.outcome_progress_prct * 100)}%`}
        />
      </div>
      <div className="initiatives">
        <ProgressBarV2
          percentage={membership.initiative_progress_prct * 100}
          totalCount={membership.total_initiatives_count}
          label={`${membership.closed_initiatives_count}/${membership.total_initiatives_count}`}
        />
      </div>
      <div className="confidence">
        <ConfidenceBar
          totalCount={membership.total_outcomes_count}
          redCount={membership.red_outcomes_count}
          yellowCount={membership.yellow_outcomes_count}
          greenCount={membership.green_outcomes_count}
          greyCount={membership.grey_outcomes_count}
          ncs={Math.round(membership.ncs)}
        />
      </div>
    </ListingLine>
  );
}

export default React.memo(PeopleLine);
