import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import AddPersonIcon from 'components/_assets/AddPersonIcon';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { t } from 'i18next';

const Container = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
`;

const OwnerOption = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.x1} ${theme.spacing.x2};

  border-radius: 8px;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
  }
  .owner-option-name {
    margin-left: ${theme.spacing.x2};
  }

  &.active {
    background: #f3f3f3;
    &:hover {
      cursor: initial;
      background: #f3f3f3;
    }
  }

  &.not-assigned {
    opacity: 0.5;
  }

  &.option-button {
    background: #f3f3f3;
    svg {
      height: 1.8rem;
      g {
        stroke: ${theme.colors.subtleText};
      }
    }
    &:hover {
      cursor: pointer;
      background: #e7e7e7;
    }
  }
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

function AddReportNode(node: NodeProps) {
  const dispatch = useDispatch();
  const { data } = node;
  const { membership } = data;

  const handleAssignReporter = () => {
    dispatch(setGlobalModalContent(`people:${membership.id}:edit.reporting`));
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CustomHandle type="target" position={Position.Top} key="in" id="in" isConnectableStart={false} />
      <OwnerOption className="active option-button" onClick={handleAssignReporter}>
        <AddPersonIcon /> <span className="owner-option-name"> {t('components.wholeOrgMinimap.addDirectReports')}</span>
      </OwnerOption>
    </Container>
  );
}

export default memo(AddReportNode);
