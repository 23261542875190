import { useMemo } from 'react';
import { Node, Edge } from 'reactflow';
import Dagre from '@dagrejs/dagre';

type NodeData = {
  expanded: boolean;
  expandable: boolean;
};

type UseExpandCollapseOptions = {
  layoutNodes?: boolean;
};

function filterCollapsedChildren(dagre: Dagre.graphlib.Graph, node: Node<NodeData>) {
  // 🚨 The current types for some of dagre's methods are incorrect. In future
  // versions of dagre this should be fixed, but for now we need to cast the return
  // value to keep TypeScript happy.
  const children = dagre.successors(node.id) as unknown as string[] | undefined;

  // Update this node's props so it knows if it has children and can be expanded
  // or not.
  node.data.expandable = !!children?.length;

  // If the node is collpased (ie it is not expanded) then we want to remove all
  // of its children from the graph *and* any of their children.
  if (!node.data.expanded) {
    while (children?.length) {
      const child = children.pop();
      if (child) {
        children.push(...(dagre.successors(child) as unknown as string[]));
        dagre.removeNode(child);
      }
    }
  }
}

function useExpandCollapse(
  nodes: Node[],
  edges: Edge[],
  showData: boolean,
  { layoutNodes = true }: UseExpandCollapseOptions = {},
): { nodes: Node[]; edges: Edge[] } {
  return useMemo(() => {
    if (!layoutNodes) return { nodes, edges };

    // 1. Create a new instance of `Dagre.graphlib.Graph` and set some default
    // properties.
    const dagre = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({})).setGraph({ rankdir: 'TB' });

    // 2. Add each node and edge to the dagre graph. Instead of using each node's
    // intrinsic width and height, we tell dagre to use the `treeWidth` and
    // `treeHeight` values. This lets you control the space between nodes.
    for (const node of nodes) {
      let height = 300;
      let width = 350;
      if (node.type === 'workspace') {
        height = 100;
      }
      if (node.data.dataExpanded) {
        height = 500;
      }

      dagre.setNode(node.id, {
        width,
        height,
        data: node.data,
      });
    }

    for (const edge of edges) {
      dagre.setEdge(edge.source, edge.target);
    }

    // 3. Iterate over the nodes *again* to determine which ones should be hidden
    // based on expand/collapse state. Hidden nodes are removed from the dagre
    // graph entirely.
    for (const node of nodes) {
      filterCollapsedChildren(dagre, node);
    }

    Dagre.layout(dagre, { disableOptimalOrderHeuristic: true });

    // // Get the ranks
    // // Calculate ranks
    // const ranks: any = {};
    // dagre.nodes().forEach((nodeId) => {
    //   if (dagre.hasNode(nodeId)) {
    //     const n = dagre.node(nodeId); // Retrieve rank of the node
    //     const rank = n.rank;

    //     if (rank === undefined) return;
    //     if (!ranks[rank]) {
    //       ranks[rank] = [];
    //     }
    //     ranks[rank].push(nodeId);
    //   }
    // });

    // console.log('ranks', ranks);

    // // Set uniform height for nodes in each rank
    // Object.values(ranks).forEach((rankNodes: any) => {
    //   const maxHeight = Math.max(...rankNodes.map((nodeId: string) => dagre.node(nodeId).height));

    //   rankNodes.forEach((nodeId: string) => {
    //     const node = dagre.node(nodeId);
    //     // dagre.setNode(nodeId, { height: maxHeight });
    //     node.height = maxHeight; // Set all nodes in the rank to the max height
    //     console.log('node', node, nodeId, node.height);
    //   });
    // });

    // // 4. Run the dagre layouting algorithm.
    // Dagre.layout(dagre, { disableOptimalOrderHeuristic: true });

    return {
      // 5. Return a new array of layouted nodes. This will not include any nodes
      // that were removed from the dagre graph in step 3.
      //
      // 💡 `Array.flatMap` can act as a *filter map*. If we want to remove an
      // element from the array, we can return an empty array in this iteration.
      // Otherwise, we can map the element like normal and wrap it in a singleton
      // array.
      nodes: nodes.flatMap((node) => {
        // This node might have been filtered out by `filterCollapsedChildren` if
        // any of its ancestors were collpased.
        if (!dagre.hasNode(node.id)) return [];

        // if it has absolute posiiton (i.e. from dragging), use that
        const { x, y } = dagre.node(node.id);

        const nodeWithPosition = dagre.node(node.id);

        let position = { x, y };

        if (node.width && node.height) {
          position = {
            x: nodeWithPosition.x - node.width / 2,
            y: nodeWithPosition.y - nodeWithPosition.height / 2,
          };
        }
        const data = { ...node.data };

        return [
          {
            ...node,
            position,
            data,
          },
        ];
      }),
      edges,
    };
  }, [nodes, edges, layoutNodes]);
}

export default useExpandCollapse;
