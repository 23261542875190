import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Checkin, Outcome } from 'types';
import * as checkinUtils from 'utils/checkinUtils';
import * as outcomeUtils from 'utils/outcomeUtils';
import { useTranslation } from 'react-i18next';

const ProgressBarContainer = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  background: ${theme.colors.B5};
  border-radius: 2px;
  overflow: hidden;
  &.green {
    background: ${theme.colors.G10};
  }
  &.grey {
    background: ${theme.colors.N10};
  }
  &.red {
    background: ${theme.colors.P30};
  }
  &.yellow {
    background: ${theme.colors.Y20};
  }
`;

const ProgressBarInner = styled.div<{ value: number }>`
  height: 100%;
  background: ${theme.colors.B50};
  border-radius: 2px;
  width: ${(props) => props.value}%;
  &.green {
    background: ${theme.colors.G50};
  }
  &.grey {
    background: ${theme.colors.N60};
  }
  &.red {
    background: ${theme.colors.R50};
  }
  &.yellow {
    background: ${theme.colors.Y50};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing.half};
  width: 100%;
  max-width: 10rem;
`;

const Label = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.B50};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.green {
    color: ${theme.colors.G60};
  }
  &.grey {
    color: ${theme.colors.N70};
  }
  &.red {
    color: ${theme.colors.R60};
  }
  &.yellow {
    color: ${theme.colors.Y70};
  }
`;

interface Props {
  outcome: Outcome;
  checkin?: Checkin | null;
  size?: 'small' | 'medium' | 'large';
  showPercentageProgress?: boolean;
}

function OutcomeCurrentValueLabel(props: Props) {
  const { outcome, checkin, showPercentageProgress } = props;
  const { t } = useTranslation();

  let formattedScore = checkin
    ? checkinUtils.checkinScore(checkin, outcome, t)
    : outcomeUtils.startingScore(outcome, t);

  if (showPercentageProgress) {
    formattedScore = `${Math.round(outcome.outcome_progress_prct * 100)}%`;
  }

  const currentConfidence = checkin ? checkin.confidence : 'grey';

  return (
    <Wrapper>
      <Label className={currentConfidence}>{formattedScore}</Label>
      {outcome.outcome_type === 'improve_metric' && (
        <ProgressBarContainer className={currentConfidence}>
          <ProgressBarInner className={currentConfidence} value={outcome.outcome_progress_prct * 100} />
        </ProgressBarContainer>
      )}
    </Wrapper>
  );
}

export default OutcomeCurrentValueLabel;
