import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
//import * as TabilityTypes from 'types';
import { useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
// API
import * as remoteApi from 'api/remote';

import KoalaButton from 'koala/components/Button';
import KoalaTextButton from 'koala/components/TextButton';
import KoalaIconButton from 'koala/components/IconButton';
import { useTranslation } from 'react-i18next';
import { Outcome } from 'types';

const BlockContent = styled.div`
  grid-area: content;
  display: flex;
  align-items: center;
  min-width: 1rem;
  gap: ${theme.spacing.half};

  .hide {
    display: none;
  }
  position: relative;
  a,
  small {
    max-width: 55rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

const BlockGrid = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
`;

const BlockFormActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1};
  button {
    margin-right: ${theme.spacing.x1};
  }
`;

interface Props {
  outcome: Outcome;
  fieldNumber: '1' | '2';
}

function OutcomeLinkBlock(props: Props) {
  const queryCache = useQueryCache();
  const { outcome, fieldNumber } = props;
  const buttonEl: any = useRef();
  const { t } = useTranslation();
  const [custom_field, setCustomField] = useState(outcome[`custom_field_${fieldNumber}`] ?? '');
  const [displayForm, setDisplayForm] = useState(false);
  const [updateOutcomeMutation, { isLoading: isUpdating }]: [any, any] = useMutation(remoteApi.updateOutcome, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.currentOutcome);
      setDisplayForm(false);
    },
  });

  const handleCustomFieldChange = (e: any) => {
    e.preventDefault();
    const custom_field = e.target.value;
    setCustomField(custom_field);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const params = {
      [`custom_field_${fieldNumber}`]: custom_field,
    };

    updateOutcomeMutation({
      outcomeId: outcome.id,
      outcome: params,
    });

    //scrollToFormInput();
  };

  const hideForm = (e: any) => {
    e.preventDefault();
    setDisplayForm(false);
  };

  const handleEdit = (value: any) => {
    setDisplayForm(true);
  };

  return (
    <BlockGrid>
      {!displayForm && (
        <BlockContent>
          {custom_field && <span>{custom_field}</span>}
          {!custom_field && <span className="subtle">{t('shared.none')}</span>}
          <KoalaIconButton iconName="edit" edge="square" size="small" onClick={handleEdit} />
        </BlockContent>
      )}
      {displayForm && (
        <BlockContent>
          <input value={custom_field} onChange={handleCustomFieldChange} placeholder={`...`} type="text" />
          <BlockFormActions>
            <KoalaButton
              size="small"
              disabled={isUpdating}
              loading={isUpdating}
              onClick={handleSubmit}
              ref={buttonEl}
              submit
            >
              {t('shared.save')}
            </KoalaButton>
            <KoalaTextButton size="small" onClick={hideForm}>
              {t('shared.cancel')}
            </KoalaTextButton>
          </BlockFormActions>
        </BlockContent>
      )}
    </BlockGrid>
  );
}

export default React.memo(OutcomeLinkBlock);
