import FormField from 'components/FormField';
import { ModalContent, ModalGrid, ModalHeader } from 'components/GlobalModal';
import queryKeys from 'config/queryKeys';
import KoalaIconButton from 'koala/components/IconButton';
import React, { useState } from 'react';
import { useMutation, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import * as remoteApi from 'api/remote';
import KoalaButton from 'koala/components/Button';
import { useDispatch } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import styled from 'styled-components';
import theme from 'theme';
import { useTranslation } from 'react-i18next';

const PlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  margin: ${theme.spacing.x1} 0px;
  a {
    color: ${theme.colors.blue};
  }
`;

const ErrorContainer = styled.p`
  color: ${theme.colors.red};
`;

function ImportUsers() {
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation();
  const translationKey = 'modals.importUsersFromCSV';
  const [cvsFile, setCSVFile] = useState<File | null>(null);

  // Mutation that will update the plan in the backend
  const [importMutation, { isLoading, isError }] = useMutation(remoteApi.importUsersFromCSV, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKeys.memberships);
      dispatch(setGlobalModalContent(''));
    },
  });

  const handleCancel = (e: any) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(''));
  };

  const handleSave = () => {
    const params = {
      workspaceId: workspaceSlug,
      file: cvsFile,
    };
    importMutation(params);
  };

  const handleCSVFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputTarget = e.target;
    setCSVFile(inputTarget.files ? inputTarget.files[0] : null);
  };

  return (
    <ModalGrid>
      <ModalHeader>
        <h2>{t(`${translationKey}.title`)}</h2>
        <KoalaIconButton onClick={handleCancel} iconName="close" />
      </ModalHeader>
      <ModalContent>
        <PlanInfoContainer>
          <div>{t(`${translationKey}.info`)}</div>
          <a
            href="https://guides.tability.app/docs/features/plans/bulk-importing-plans#bulk-importing-plans-with-their-data-via-spreadsheet"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t(`${translationKey}.option1Docs`)}
          </a>
        </PlanInfoContainer>
        <FormField>
          <label>{t(`${translationKey}.file`)}</label>
          <input type="file" accept=".csv" onChange={handleCSVFileChange} />
        </FormField>
        <KoalaButton onClick={handleSave} loading={isLoading} disabled={isLoading} size="small">
          {t(`${translationKey}.option1Button`)}
        </KoalaButton>
        {isError && (
          <FormField>
            <ErrorContainer className="error">{t(`${translationKey}.error`)}</ErrorContainer>
          </FormField>
        )}
      </ModalContent>
    </ModalGrid>
  );
}

export default ImportUsers;
