import queryKeys from 'config/queryKeys';
import KoalaButton from 'koala/components/Button';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Relationship, Workspace } from 'types';
import styled from 'styled-components';
import theme from 'theme';
import * as remoteApi from 'api/remote';
import * as workspaceUtils from 'utils/workspaceUtils';
import { Outcome } from 'types';
import KoalaLoader from 'koala/components/Loader';
import { Edge, Node, ReactFlowProvider } from 'reactflow';
import Map from './Map';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import UpgradeRequired from './UpgradeRequired';
import { edgeDefaultsUp, nodeDefaults } from 'utils/mapUtils';

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .actions {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.6);
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  max-height: 25rem;
  z-index: 10;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -12rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

interface Props {
  outcome: Outcome;
}

function OutcomeMinimap(props: Props) {
  const { outcome } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const [hasFetchedRelationships, setHasFetchedRepationships] = useState(false);
  const [parentRelationships, setParentRelationships] = useState<Relationship[]>([]);
  const [contributingRelationships, setContributingRelationships] = useState<Relationship[]>([]);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);

  // get relationships
  const queryKeyOutcomes = [queryKeys.relationships, outcome?.id, 'outcomes'];
  const staleTime = queryKeys.staleTime;

  const { isLoading: isLoadingOutcomes, isFetching: isFetchingOutcomes } = useQuery(
    queryKeyOutcomes,
    remoteApi.fetchRelationshipsForOutcome,
    {
      staleTime,
      onSuccess: (response: any) => {
        const relationships: Relationship[] = response.data;
        const parentOutcomes: Relationship[] = [];
        const contributingOutcomes: Relationship[] = [];
        relationships.forEach((relationship) => {
          if (relationship.source_id === outcome.id && relationship.relationship_type === 'contributes_to') {
            parentOutcomes.push(relationship);
          }
          if (relationship.target_id === outcome.id && relationship.relationship_type === 'contributes_to') {
            contributingOutcomes.push(relationship);
          }
        });
        setParentRelationships(parentOutcomes);
        setContributingRelationships(contributingOutcomes);
        setHasFetchedRepationships(true);
      },
    },
  );

  const fullScreenClass = fullScreenEnabled ? 'full-screen' : '';

  if (isLoadingOutcomes || isFetchingOutcomes) {
    return <KoalaLoader />;
  }

  const handleOutcomeClicked = (e: React.MouseEvent<HTMLDivElement>, outcomeSlug: string) => {
    e.preventDefault();
    e.stopPropagation();
    setFullScreenEnabled(false);
    history.push(`#outcome:${outcomeSlug}:show`);
  };

  let height = 290;
  let width = 350;

  const nodes: Node[] = [
    {
      id: outcome.nano_slug,
      data: { outcome, expanded: true, dataExpanded: false, isExpandable: false, isCurrent: true },
      type: 'outcome',
      style: {
        height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    },
  ];
  const edges: Edge[] = [];

  parentRelationships.forEach((relationship) => {
    const parentId = relationship.target.nano_slug;
    nodes.push({
      id: parentId,
      type: 'outcome',
      data: {
        outcome: relationship.target,
        dataExpanded: false,
        expanded: true,
        isExpandable: false,
        isCurrent: false,
      },
      style: {
        height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    });
    edges.push({
      id: `${parentId}:${outcome.nano_slug}`,
      target: outcome.nano_slug,
      source: parentId,
      ...edgeDefaultsUp,
    });
  });

  contributingRelationships.forEach((relationship) => {
    const childId = relationship.source.nano_slug;
    nodes.push({
      id: childId,
      type: 'child',
      data: {
        outcome: relationship.source,
        dataExpanded: false,
        expanded: false,
        isExpandable: true,
        isCurrent: false,
      },
      style: {
        height: height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    });
    edges.push({
      id: `${outcome.nano_slug}:${childId}`,
      target: childId,
      source: outcome.nano_slug,
      ...edgeDefaultsUp,
    });
  });

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);

  // Check the upgrade required flag
  let upgradeRequired = !hasPremiumSubscription;

  return (
    <Container>
      {upgradeRequired && (
        <>
          <Mask />
          <UpgradeModal>
            <UpgradeRequired />
          </UpgradeModal>
        </>
      )}
      <Content className={fullScreenClass}>
        <HeaderContainer>
          <span className="title">Outcome Minimap</span>
          <div className="actions">
            <KoalaButton onClick={() => setFullScreenEnabled(!fullScreenEnabled)} appearance="secondary" size="small">
              {fullScreenEnabled ? t('shared.exitFullScreen') : t('shared.enterFullScreen')}
            </KoalaButton>
          </div>
        </HeaderContainer>
        {hasFetchedRelationships && (
          <ReactFlowProvider>
            <Map
              initialEdges={edges}
              initialNodes={nodes}
              fullScreenEnabled={fullScreenEnabled}
              handleOutcomeClicked={handleOutcomeClicked}
            />
          </ReactFlowProvider>
        )}
      </Content>
    </Container>
  );
}

export default OutcomeMinimap;
