import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Edge,
  Node,
  Background,
  Controls,
  useReactFlow,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
  NodeMouseHandler,
} from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import theme from 'theme';
import WorkspaceNode from 'pages/WorkspaceMapV3/WorkspaceNode';
import useExpandCollapse from './usExpandCollapse';
import MembershipNode from './MembershipNode';
import AddReportNode from './AddReportNode';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const nodeTypes = {
  membership: MembershipNode,
  workspace: WorkspaceNode,
  addReport: AddReportNode,
};

interface Props {
  initialEdges: Edge[];
  initialNodes: Node[];
  fullScreenEnabled: boolean;
  showData: boolean;
}

function Map(props: Props) {
  const { initialEdges, initialNodes, fullScreenEnabled, showData } = props;
  const reactFlowInstance = useReactFlow();
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
  }, []);
  const onEdgesChange: OnEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), []);

  // set expanded when arrow is clicked in MembershipNode
  const toggleNodeExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, expanded: !n.data.expanded },
            };
          }

          return n;
        }),
      );
    },
    [setNodes],
  );

  // set expanded when arrow is clicked in MembershipNode
  const toggleDataExpand: NodeMouseHandler = useCallback(
    (_, node) => {
      setNodes((nds) =>
        nds.map((n) => {
          if (n.id === node.id) {
            return {
              ...n,
              data: { ...n.data, dataExpanded: !n.data.dataExpanded },
            };
          }

          return n;
        }),
      );
    },
    [setNodes],
  );

  // when initial values change, set edges and nodes
  useEffect(() => {
    setEdges([...initialEdges]);
    // pass handleNodeExpand callback to nodes
    setNodes(() =>
      initialNodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            toggleNodeExpand,
            toggleDataExpand,
          },
        };
      }),
    );
    // setFitNeeded(true);
  }, [toggleNodeExpand, toggleDataExpand, initialEdges, initialNodes]);

  // This function filters the nodes to avoid duplicate
  // This works because findIndex always returns the first element found in an array
  // See https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
  const filteredNodes = nodes.filter(
    (node, index, self) => index === self.findIndex((reference) => reference.id === node.id),
  );
  // This function filters the edges to avoid duplicate (see above)
  const filteredEdges = edges.filter(
    (edge, index, self) => index === self.findIndex((reference) => reference.id === edge.id),
  );
  // handle expand, collapse and layout
  const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(filteredNodes, filteredEdges, showData, {
    layoutNodes: true,
  });
  // fit graph in screen
  useEffect(() => {
    const timer = setTimeout(() => {
      reactFlowInstance.fitView({ duration: 300, maxZoom: 0.6, minZoom: 0.6 });
    }, 200); // Adjust the delay (in milliseconds) as needed

    return () => clearTimeout(timer);
  }, [reactFlowInstance]);

  // fit graph in screen
  useEffect(() => {
    reactFlowInstance.fitView({ duration: 300, maxZoom: 0.6, minZoom: 0.6 });
  }, [reactFlowInstance, fullScreenEnabled]);

  return (
    <Container>
      <ReactFlow
        id="whole-org-minimap"
        nodes={visibleNodes}
        edges={visibleEdges}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        minZoom={0.1}
        maxZoom={2}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        style={{ background: theme.colors.N3 }}
        proOptions={{ account: 'paid-pro', hideAttribution: true }}
        defaultViewport={{
          x: 0,
          y: 0,
          zoom: 0.8,
        }}
      >
        <Background color={theme.colors.N20} size={2} />
        <Controls />
      </ReactFlow>
    </Container>
  );
}

export default Map;
