import React, { useState } from 'react';
import { Membership, Workspace } from 'types';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { Edge, Node, ReactFlowProvider } from 'reactflow';
import KoalaButton from 'koala/components/Button';
import UpgradeRequired from 'components/PeopleMinimap/UpgradeRequired';
import Map from './Map';
import { ModalHeader } from 'components/GlobalModal';
import { ModalContent } from 'components/GlobalModal';
import { edgeDefaultsUp, nodeDefaults } from 'utils/mapUtils';

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.6);
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  max-height: 25rem;
  z-index: 10;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -20rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .actions {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
  }
`;

function WholeOrgMinimap() {
  const { t } = useTranslation();
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [hasFetchedTopLevel, setHasFetchedTopLevel] = useState(false);
  const translationKey = 'components.wholeOrgMinimap';

  // get top level
  const topLevelQueryKey = [queryKeys.workspaces, 'top_level_managers', currentWorkspace.slug];
  const staleTime = 0;
  const { data: topLevelData, isLoading: isLoadingTopLevel } = useQuery(
    topLevelQueryKey,
    remoteApi.fetchWorkspaceTopLevelManagers,

    {
      staleTime,
      onSuccess: () => {
        setHasFetchedTopLevel(true);
      },
    },
  );

  const nodes: Node[] = [
    {
      id: 'workspace',
      data: {
        expanded: true,
        workspace: currentWorkspace,
      },
      type: 'workspace',
      ...nodeDefaults,
    },
  ];
  const edges: Edge[] = [];

  const topLevel = topLevelData?.data || [];
  topLevel.forEach((membership: Membership) => {
    edges.push({
      id: `workspace:${membership.id}`,
      target: membership.id,
      source: 'workspace',
      ...edgeDefaultsUp,
    });

    nodes.push({
      id: `${membership.id}`,
      type: 'membership',
      data: {
        expanded: false,
        membership,
        path: `${membership.id}/`,
      },
      ...nodeDefaults,
    });
  });

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);

  const hasTopLevel = topLevel.length > 0;
  // Check the upgrade required flag
  let upgradeRequired = !hasPremiumSubscription;

  const fullScreenClass = fullScreenEnabled ? 'full-screen' : '';

  return (
    <Container>
      {upgradeRequired && (
        <>
          <Mask />
          <UpgradeModal>
            <UpgradeRequired />
          </UpgradeModal>
        </>
      )}
      {hasFetchedTopLevel && !hasTopLevel && (
        <>
          <Mask />
          <UpgradeModal>
            <ModalHeader>
              <h2>{t(`${translationKey}.noTopLevel`)}</h2>
            </ModalHeader>
            <ModalContent>
              <p>{t(`${translationKey}.noTopLevelInfo`)}</p>
            </ModalContent>
          </UpgradeModal>
        </>
      )}

      <Content className={fullScreenClass}>
        <HeaderContainer>
          <span className="title">{t(`${translationKey}.minimapTitle`)}</span>
          <div className="actions">
            <KoalaButton onClick={() => setFullScreenEnabled(!fullScreenEnabled)} appearance="secondary" size="small">
              {fullScreenEnabled ? t('shared.exitFullScreen') : t('shared.enterFullScreen')}
            </KoalaButton>
          </div>
        </HeaderContainer>
        {!isLoadingTopLevel && hasFetchedTopLevel && hasTopLevel && (
          <ReactFlowProvider>
            <Map initialEdges={edges} initialNodes={nodes} fullScreenEnabled={fullScreenEnabled} showData={true} />
          </ReactFlowProvider>
        )}
      </Content>
    </Container>
  );
}

export default WholeOrgMinimap;
