import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Membership, Workspace } from 'types';

import * as remoteApi from 'api/remote';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import KoalaAvatar from 'koala/components/Avatar';
import KoalaButton from 'koala/components/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalModalContent } from 'state/actions/globalUIActions';
import { useHistory } from 'react-router-dom';
import * as workspaceUtils from 'utils/workspaceUtils';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-bottom: ${theme.spacing.x3};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 10rem auto;
  gap: ${theme.spacing.x1};
  margin-bottom: ${theme.spacing.x2};

  label {
    font-size: 1.2rem;
    color: ${theme.colors.subtleText};
    width: 10rem;
    min-width: 10rem;
  }
`;
const UserSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.x1};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.x2};

  h3 {
    margin: 0px;
  }
`;

const EmptyState = styled.div`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;

interface Props {
  membership: Membership;
}

function ReportToSelection(props: Props) {
  const { membership } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const currentMembership: Membership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [manager, setManager] = useState<Membership | null>(null);
  const [direct_reports, setDirectReports] = useState<Membership[]>([]);

  const staleTime = 0;
  const direct_reportsQueryKey = [queryKeys.reporting, 'direct_reports', membership.id];
  const managerQueryKey = [queryKeys.reporting, 'manager', membership.id];

  // get direct_reports
  useQuery(direct_reportsQueryKey, remoteApi.fetchMembershipDirectReports, {
    staleTime,
    onSuccess: (response) => {
      const memberships: Membership[] = response.data;
      setDirectReports(memberships);
    },
  });

  // get manager
  useQuery(managerQueryKey, remoteApi.fetchMembershipManager, {
    staleTime,
    onSuccess: (response) => {
      const result = response.data;
      setManager(result);
    },
  });

  const handleManageReporting = () => {
    const blockId = `people:${membership.id}:edit.reporting`;
    dispatch(setGlobalModalContent(blockId));
  };

  const handleAvatarClick = (id?: string) => {
    if (!id) {
      return;
    }
    const path = `/${currentWorkspace.slug}/people/${id}`;
    history.push(path);
  };

  const isCurrentUser = membership.id === currentMembership.id;

  let headerLabel = t('workspaceProfile.network.teamNetwork');
  let headerButtonLabel = t('shared.manage');
  let managerLabel = t('workspaceProfile.network.teamManager');
  let directReportsLabel = t('workspaceProfile.network.teamReports');

  if (isCurrentUser) {
    headerLabel = t('workspaceProfile.network.myNetwork');
    headerButtonLabel = t('shared.manage');
    managerLabel = t('workspaceProfile.network.myManager');
    directReportsLabel = t('workspaceProfile.network.myReports');
  }

  const showUpgrade =
    !workspaceUtils.hasV4PlusSubscription(currentWorkspace) && !workspaceUtils.hasPremiumSubscription(currentWorkspace);

  const isTopLevel = membership.is_top_manager;

  return (
    <Container>
      <Header>
        <h3>{headerLabel}</h3>
        <KoalaButton onClick={handleManageReporting} appearance="secondary" size="small">
          {showUpgrade && <KoalaIcon iconName="upgrade" iconSize="small" />}
          {headerButtonLabel}
        </KoalaButton>
      </Header>
      <Row>
        <label>{managerLabel}</label>
        {isTopLevel && <span>{t('workspaceProfile.network.topLevel')}</span>}
        {!isTopLevel && (
          <UserSelection>
            {!manager && (
              <EmptyState>
                <KoalaAvatar membership={null} tooltipType="card" />
                <span>{t('workspaceProfile.network.notSet')}</span>
              </EmptyState>
            )}
            {manager && (
              <KoalaAvatar membership={manager} handleClick={() => handleAvatarClick(manager.id)} tooltipType="card" />
            )}
          </UserSelection>
        )}
      </Row>
      <Row>
        <label>{directReportsLabel}</label>
        <UserSelection>
          {direct_reports.map((reporter, i) => (
            <KoalaAvatar
              key={i}
              membership={reporter}
              handleClick={() => handleAvatarClick(reporter.id)}
              tooltipType="card"
            />
          ))}
          {direct_reports.length === 0 && (
            <EmptyState>
              <KoalaAvatar membership={null} tooltipType="card" />
              <span>{t('workspaceProfile.network.notSet')}</span>
            </EmptyState>
          )}
        </UserSelection>
      </Row>
    </Container>
  );
}

export default ReportToSelection;
