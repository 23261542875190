import React, { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import OutcomeContent from './OutcomeContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 4px;

  &.current {
    border: 1px solid ${theme.colors.B50};
  }

  &.show-data {
    height: 430px;
  }
  .expand-plan-button {
    transition: all ease 0.2s;
    transform: rotate(90deg);
    &.plan--expanded {
      transform: rotate(-90deg);
    }
  }
`;
const OutcomeContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

function OutcomeNode(node: NodeProps) {
  const { data } = node;
  const { outcome, dataExpanded, toggleDataExpand, isCurrent, handleOutcomeClicked } = data;
  const toggleDataExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
    if (toggleDataExpand) {
      toggleDataExpand(event, node);
    }
  };

  const expandedDataClassName = dataExpanded ? 'show-data' : '';
  const isCurrentClassName = isCurrent ? 'current' : '';
  return (
    <Container onClick={(e) => e.stopPropagation()} className={`${expandedDataClassName} ${isCurrentClassName}`}>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <OutcomeContainer className={expandedDataClassName}>
        <OutcomeContent
          outcome={outcome}
          dataExpanded={dataExpanded}
          toggleDataExpanded={toggleDataExpanded}
          handleOutcomeClicked={handleOutcomeClicked}
        />
      </OutcomeContainer>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(OutcomeNode);
