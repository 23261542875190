import React, { Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import * as TabilityTypes from 'types';
import { useInfiniteQuery, useMutation, useQueryCache } from 'react-query';
import queryKeys from 'config/queryKeys';
import theme from 'theme';
import parse from 'parse-link-header';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

// API
import * as remoteApi from 'api/remote';

// Components
import { ChromeContent, ChromeSideNav } from 'components/Chrome';
import ContentTitle from 'components/ContentTitle';
import Loader from 'components/Loader';
import WorkspaceSettingsNav from 'components/WorkspaceSettingsNav';
import _ from 'lodash';
import KoalaButton from 'koala/components/Button';
import KoalaAvatar from 'koala/components/Avatar';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNowLocale } from 'utils/dateUtils';
import KoalaSelect, { KoalaSelectOption } from 'koala/components/Select';
import { ValueType } from 'react-select';
import { AxiosResponse } from 'axios';
import KoalaCheckbox from 'koala/components/Checkbox';
import KoalaTextBadge from 'koala/components/TextBadge';
import KoalaTextButton from 'koala/components/TextButton';
import { SELECTED_MEMBERSHIPS_KEY } from 'config/constants';
import RoleBadge from 'components/RoleBadge';

const Wrapper = styled.div`
  padding: ${theme.spacing.x4};
  max-width: 110rem;
  margin: 0 auto;
`;

const LoadMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing.x3};
`;

const CustomTableWrapper = styled.div`
  overflow: auto;
  max-width: 100%;
  min-height: 50vh;
`;

const CustomTable = styled.table`
  overflow: auto;
  font-size: 1.2rem;
  margin-top: ${theme.spacing.x2};

  tr {
    cursor: pointer;
    &:hover {
      background-color: #f3f3f3;
    }
  }
  td {
    padding: ${theme.spacing.x1} ${theme.spacing.x1};
    vertical-align: middle;
    white-space: nowrap;

    .error {
      color: ${theme.colors.red};
    }
  }

  th {
    padding: ${theme.spacing.x1} ${theme.spacing.x2};
    text-align: left;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1rem;
    white-space: nowrap;
    &:nth-child(1) {
      width: 5rem;
    }
    &:nth-child(2) {
    }
  }
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
`;

const SelectionMenu = styled.div`
  display: flex;
  margin: ${theme.spacing.x1} ${theme.spacing.x3};
  gap: ${theme.spacing.x1};
  justify-content: end;
  align-items: center;

  &.show-selection-menu {
    height: 32px;
    transition: height 0.4s ease;
  }
  &.hide-selection-menu {
    height: 0;
    overflow-y: hidden;
    transition: height 0.4s ease;
  }
`;

const TableRow = styled.tr`
  .checkbox-hover {
    display: none;
  }
  :hover {
    .checkbox-hover {
      display: flex;
    }
  }
`;

interface Props {
  workspace: TabilityTypes.Workspace;
}

function WorkspaceSettingsDetails(props: Props) {
  const { workspace } = props;
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [roleFilter, setRoleFilter] = useState<string | null>();
  const [twoFAFilter, setTwoFAFilter] = useState<string | null>();
  const [scimFilter, setScimFilter] = useState<string | null>();
  const [resultsCount, setResultsCount] = useState<string | null>(null);
  const [selectedMemberships, setSelectedMemberships] = useState<TabilityTypes.Membership[]>([]);
  const currentMembership = useSelector((state: any) => state.session.currentMembership, shallowEqual);
  const isOwner = currentMembership.role === 'owner';

  // Params used for the search
  const [nameToSearch, setNameToSearch] = useState('');
  const [searchedMemberships, setSearchedMemberships] = useState<AxiosResponse<TabilityTypes.Membership[]>[]>([]);

  // Search query attributes
  const searchMembershipsQueryKey = [
    queryKeys.memberships,
    workspace.id,
    {
      name: nameToSearch,
      role: roleFilter,
      otp_required_for_login: twoFAFilter,
      scim_status: scimFilter,
      per_page: 25,
    },
  ];
  const getNextPage = (response: AxiosResponse<TabilityTypes.Membership[]>) => {
    if (response && response.headers && response.headers.link) {
      const links = response.headers.link;
      const parsed = parse(links);
      if (parsed && parsed.next) {
        return parsed.next.page;
      }
    }
    return null;
  };

  // Search params for search
  const { isLoading, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
    searchMembershipsQueryKey,
    remoteApi.fetchWorkspaceMemberships,
    {
      getFetchMore: (lastGroup, allGroups) => {
        return getNextPage(lastGroup);
      },
      onSuccess: (response: AxiosResponse<TabilityTypes.Membership[]>[]) => {
        setSearchedMemberships(response);
        const resultsCount = response[0].headers['x-total'];
        setResultsCount(resultsCount);
      },
    },
  );

  const [deleteMembershipMutation, { isLoading: isRemovingMembership }] = useMutation(remoteApi.deleteMembership, {
    onSuccess: () => {
      queryCache.invalidateQueries([queryKeys.memberships, workspace.id]);
      clearSelectedMemberships();
    },
  });

  const [updateMembershipMutation, { isLoading: isUpdatingMembership }] = useMutation(remoteApi.updateMembership);

  // START SEARCH LOGIC
  const performSearch = (newName: string) => {
    setNameToSearch(newName);
  };

  const debouncePerformSearch = useRef(
    _.debounce((newName: string) => performSearch(newName), 500, {
      maxWait: 2000,
    }),
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    debouncePerformSearch.current(newName);
  };

  const handleInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const action = `workspace:${workspace.slug}:invite`;
    dispatch(setGlobalModalContent(action));
  };

  const handleImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(setGlobalModalContent(`workspace:${workspace.slug}:import.users`));
  };

  const filterByRole = (option: ValueType<KoalaSelectOption, boolean>) => {
    const optionValue = option as KoalaSelectOption;
    setRoleFilter(optionValue && typeof optionValue.value === 'string' ? optionValue.value : null);
  };

  const roleOptions = [
    { label: t('role.user'), value: 'user' },
    { label: t('role.admin'), value: 'admin' },
    { label: t('role.owner'), value: 'owner' },
    { label: t('role.readonly'), value: 'readonly' },
  ];

  const getSelectedRoleOptionsFromFilter = () => {
    return roleOptions.filter((o) => {
      return o.value === roleFilter;
    });
  };

  const filterByTwoFA = (option: ValueType<KoalaSelectOption, boolean>) => {
    const optionValue = option as KoalaSelectOption;
    setTwoFAFilter(optionValue && typeof optionValue.value === 'string' ? optionValue.value : null);
  };

  const twoFAOptions = [
    { label: t('workspaceSettingsMemberships.twoFAEnabled'), value: 'enabled' },
    { label: t('workspaceSettingsMemberships.twoFADisabled'), value: 'disabled' },
  ];

  const getSelectedTwoFAOptionsFromFilter = () => {
    return twoFAOptions.filter((o) => {
      return o.value === twoFAFilter;
    });
  };

  const filterByScim = (option: ValueType<KoalaSelectOption, boolean>) => {
    const optionValue = option as KoalaSelectOption;
    setScimFilter(optionValue && typeof optionValue.value === 'string' ? optionValue.value : null);
  };

  const scimOptions = [
    { label: t('workspaceSettingsMemberships.scimEnabled'), value: 'enabled' },
    { label: t('workspaceSettingsMemberships.scimDisabled'), value: 'disabled' },
  ];

  const getSelectedScimOptionsFromFilter = () => {
    return scimOptions.filter((o) => {
      return o.value === scimFilter;
    });
  };

  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    membership: TabilityTypes.Membership,
    isSelected: boolean,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    let newList = isSelected
      ? selectedMemberships.filter((selectedMember) => selectedMember !== membership)
      : selectedMemberships.concat(membership);
    setSelectedMemberships(newList);
  };

  const removeMemberships = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedMemberships.length === 1) {
      if (
        window.confirm(t('workspaceSettingsMemberships.confirmRemove') ?? 'Are you sure you want to remove this user?')
      ) {
        deleteMembershipMutation(selectedMemberships[0].id);
      }
    } else {
      const selectedList = selectedMemberships.map((membership) => ({
        id: membership.id,
        title: membership.cached_fullname ?? membership.cached_email,
      }));
      localStorage.setItem(SELECTED_MEMBERSHIPS_KEY, JSON.stringify(selectedList));
      dispatch(setGlobalModalContent('memberships::delete'));
    }
  };

  const handleChangeMembershipRoles = (option: ValueType<KoalaSelectOption, boolean>) => {
    const optionValue = option as KoalaSelectOption;
    if (selectedMemberships.length > 0) {
      const role = optionValue.value || '';
      const confirmMessage = t('workspaceSettingsMemberships.confirmRoleChange', { role }) || 'Are you sure?';
      if (optionValue.value && window.confirm(confirmMessage)) {
        const promises = selectedMemberships.map((membership) => {
          const params = {
            role,
          };
          return updateMembershipMutation({
            membershipId: membership.id,
            membership: params,
          });
        });

        Promise.all(promises)
          .then(() => {
            queryCache.invalidateQueries([queryKeys.memberships, workspace.id]);
            clearSelectedMemberships();
          })
          .catch((err) => {
            alert(t('errors.serverError'));
          });
      }
    }
  };

  const clearSelectedMemberships = () => {
    setSelectedMemberships([]);
  };

  const getRoleOptions = () => {
    if (isOwner) {
      return roleOptions;
    }
    return roleOptions.filter((o) => o.value !== 'owner');
  };

  const showSelectionMenuClass = selectedMemberships.length > 0 ? 'show-selection-menu' : 'hide-selection-menu';

  const resultsCountInt = parseInt(resultsCount ?? '0', 0);

  return (
    <Fragment>
      <Helmet>
        <title>
          {workspace.name} | {t('workspaceSettingsMemberships.title')} | Tability
        </title>
      </Helmet>
      <ChromeSideNav>
        <WorkspaceSettingsNav />
      </ChromeSideNav>
      <ChromeContent>
        <Wrapper>
          <ContentTitle>
            <h1>
              {t('workspaceSettingsMemberships.title')} ({resultsCountInt})
            </h1>
            <FilterRow>
              <KoalaButton onClick={handleImport} appearance="secondary">
                {t('workspaceSettingsMemberships.importTeam')}
              </KoalaButton>
              <KoalaButton onClick={handleInvite}>{t('workspaceSettingsMemberships.inviteTeam')}</KoalaButton>
            </FilterRow>
          </ContentTitle>
          <FilterRow>
            <input
              type="text"
              onChange={handleSearch}
              placeholder={t('workspaceSettingsMemberships.filterPlaceholder') ?? 'Filter users'}
              className="small"
            />
            <KoalaSelect
              isClearable
              placeholder={t('workspaceSettingsMemberships.roleFilterPlaceholder')}
              options={roleOptions}
              handleChange={filterByRole}
              selectedOption={getSelectedRoleOptionsFromFilter()}
            />
            <KoalaSelect
              isClearable
              placeholder={t('workspaceSettingsMemberships.twoFAFilterPlaceholder')}
              options={twoFAOptions}
              handleChange={filterByTwoFA}
              selectedOption={getSelectedTwoFAOptionsFromFilter()}
            />
            <KoalaSelect
              isClearable
              placeholder={t('workspaceSettingsMemberships.scimFilterPlaceholder')}
              options={scimOptions}
              handleChange={filterByScim}
              selectedOption={getSelectedScimOptionsFromFilter()}
            />
          </FilterRow>
          <SelectionMenu className={showSelectionMenuClass}>
            <KoalaTextBadge variant="blue-light">
              {t(`workspaceSettingsMemberships.selectedMembers`, { count: selectedMemberships.length })}
            </KoalaTextBadge>
            <KoalaSelect
              size="small"
              placeholder={t('workspaceSettingsMemberships.roleChange')}
              options={getRoleOptions()}
              handleChange={handleChangeMembershipRoles}
              selectedOption={getSelectedRoleOptionsFromFilter()}
            />

            <KoalaButton appearance="subtle" size="small" onClick={removeMemberships} loading={isRemovingMembership}>
              {t(`workspaceSettingsMemberships.details.removeButton`, { count: selectedMemberships.length })}
            </KoalaButton>
            <KoalaTextButton onClick={clearSelectedMemberships}>{t(`shared.cancel`)}</KoalaTextButton>
          </SelectionMenu>
          <CustomTableWrapper>
            <CustomTable>
              <tr>
                <th />
                <th>{t('workspaceSettingsMemberships.user')}</th>
                <th>{t('workspaceSettingsMemberships.email')}</th>
                <th>{t('workspaceSettingsMemberships.role')}</th>
                <th>{t('workspaceSettingsMemberships.joined')}</th>
                <th>{t('workspaceSettingsMemberships.last_activity_at')}</th>
                <th>{t('workspaceSettingsMemberships.confirmed')}</th>
                <th>{t('workspaceSettingsMemberships.2FA')}</th>
                <th>{t('workspaceSettingsMemberships.slack')}</th>
                <th>{t('workspaceSettingsMemberships.apiAccess')}</th>
                <th>{t('workspaceSettingsMemberships.scim')}</th>
              </tr>
              {(isLoading || isUpdatingMembership) && <Loader size="medium" />}
              {searchedMemberships.map((group, i: number) => {
                return (
                  <Fragment key={i}>
                    {group.data.map((membership: TabilityTypes.Membership) => {
                      const { user, slack_enabled, public_api_enabled } = membership;
                      const name = user.fullname || user.email;
                      const otp_required_for_login = user.otp_required_for_login;
                      const isConfirmed = user.confirmed_at;
                      const path = `/${workspace.slug}/settings/users/${membership.id}`;
                      const isSelected = selectedMemberships.includes(membership);
                      return (
                        <TableRow key={membership.id} onClick={() => history.push(path)}>
                          <td>
                            <KoalaAvatar membership={membership} size={3.2} tooltipType="card" />
                          </td>
                          <td>
                            <Link to={path}>{name}</Link>
                          </td>
                          <td>{user.email}</td>
                          <td>
                            <RoleBadge role={membership.role} />
                          </td>
                          <td>{formatDistanceToNowLocale(membership.created_at, i18n, true)}</td>
                          <td>
                            {user.last_activity_at && (
                              <>{formatDistanceToNowLocale(user.last_activity_at, i18n, true)}</>
                            )}
                            {!user.last_activity_at && <>–</>}
                          </td>
                          <td>
                            {isConfirmed && <span>&#10004;</span>}
                            {!isConfirmed && <span className="error">&#10008;</span>}
                          </td>
                          <td>
                            {otp_required_for_login && <span>&#10004;</span>}
                            {!otp_required_for_login && <span className="error">&#10008;</span>}
                          </td>
                          <td>
                            {slack_enabled && <span>&#10004;</span>}
                            {!slack_enabled && <span className="error">&#10008;</span>}
                          </td>
                          <td>
                            {public_api_enabled && <span>&#10004;</span>}
                            {!public_api_enabled && <span className="error">&#10008;</span>}
                          </td>
                          <td>
                            {membership.scim_enabled && <span>&#10004;</span>}
                            {!membership.scim_enabled && <span className="error">&#10008;</span>}
                          </td>
                          <td width={52}>
                            {membership.role !== 'owner' && (
                              <KoalaCheckbox
                                checked={isSelected}
                                size="small"
                                edge="square"
                                handleChange={(e) => handleCheckbox(e, membership, isSelected)}
                                className={selectedMemberships.length > 0 ? 'checkbox-show' : 'checkbox-hover'}
                              />
                            )}
                          </td>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </CustomTable>
          </CustomTableWrapper>
          {canFetchMore && (
            <LoadMore>
              <KoalaButton onClick={() => fetchMore()} appearance="secondary">
                {t('shared.loadMore')}
              </KoalaButton>
              {isFetchingMore && <Loader size="small" />}
            </LoadMore>
          )}
        </Wrapper>
      </ChromeContent>
    </Fragment>
  );
}

export default WorkspaceSettingsDetails;
