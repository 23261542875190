import { Position, MarkerType } from 'reactflow';

export const edgeDefaultsUp = {
  targetHandle: 'in',
  sourceHandle: 'out',
  type: 'step',
  markerStart: {
    type: MarkerType.ArrowClosed,
  },
};

export const edgeDefaultsDown = {
  targetHandle: 'in',
  sourceHandle: 'out',
  type: 'step',
  markerStart: {
    type: MarkerType.ArrowClosed,
  },
};

export const nodeDefaults = {
  position: { x: 0, y: 0 },
  sourcePosition: Position.Bottom,
  targetPosition: Position.Top,
};
