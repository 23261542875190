import React, { useState } from 'react';
import { Membership, Workspace } from 'types';
import * as workspaceUtils from 'utils/workspaceUtils';
import styled from 'styled-components';
import theme from 'theme';

// Components
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import queryKeys from 'config/queryKeys';
import * as remoteApi from 'api/remote';
import { Edge, Node, ReactFlowProvider } from 'reactflow';
import Map from './Map';
import UpgradeRequired from './UpgradeRequired';
import KoalaButton from 'koala/components/Button';
import { edgeDefaultsUp, nodeDefaults } from 'utils/mapUtils';

const Container = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0.6);
`;

const UpgradeModal = styled.div`
  position: absolute;
  left: 50%;
  max-height: 25rem;
  z-index: 10;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  height: 25rem;
  top: 50%;
  width: 60rem;
  max-width: 60rem;
  margin-left: -30rem;
  margin-top: -12rem;
  background: #fff;

  @media ${theme.devices.mobile} {
    width: 100%;
    margin: 20rem 0 0 0;
    margin-top: -20rem;
    left: unset;
    overflow: auto;
    height: 30rem;
    max-height: 30rem;
  }
`;

const HeaderContainer = styled.div`
  padding: ${theme.spacing.x1} ${theme.spacing.x2};
  border-bottom: 1px solid ${theme.colors.N10};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .actions {
    display: flex;
    gap: ${theme.spacing.x1};
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.N0};
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
  }
`;

interface Props {
  rootMembership: Membership;
}

function PeopleMinimap(props: Props) {
  const { rootMembership } = props;
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const { t } = useTranslation();
  const [fullScreenEnabled, setFullScreenEnabled] = useState(false);
  const [showData, setShowData] = useState(false);
  const [hasFetchedReportsData, setHasFetchedReportsData] = useState(false);
  const [hasFetchedManagerData, setHasFetchedManagerData] = useState(false);

  const hasPremiumSubscription = workspaceUtils.hasPremiumSubscription(currentWorkspace);

  // Check the upgrade required flag
  let upgradeRequired = !hasPremiumSubscription;

  const staleTime = 0; // 5 minutes
  const membershipId = rootMembership.id;
  const direct_reportsQueryKey = [queryKeys.reporting, 'direct_reports', membershipId];
  const managerQueryKey = [queryKeys.reporting, 'manager', membershipId];

  // get direct_reports
  const { data: directReportsData, isLoading: isLoadingDirectReports } = useQuery(
    direct_reportsQueryKey,
    remoteApi.fetchMembershipDirectReports,
    {
      staleTime,
      onSuccess: () => setHasFetchedReportsData(true),
    },
  );

  // get manager
  const { data: managerData, isLoading: isLoadingManager } = useQuery(
    managerQueryKey,
    remoteApi.fetchMembershipManager,
    {
      staleTime,
      onSuccess: () => setHasFetchedManagerData(true),
    },
  );

  let height = 200;
  let width = 350;

  if (showData) {
    height = 600;
    width = 600;
  }

  const nodes: Node[] = [
    {
      id: rootMembership.id,
      data: { membership: rootMembership, expanded: true, dataExpanded: showData, path: `${rootMembership.id}/` },
      type: 'root',
      style: {
        height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    },
  ];
  const edges: Edge[] = [];

  const directReports = directReportsData?.data || [];
  const manager = managerData?.data || null;

  if (manager) {
    edges.push({
      id: `${manager.id}:${rootMembership.id}`,
      target: rootMembership.id,
      source: manager.id,
      ...edgeDefaultsUp,
    });

    nodes.push({
      id: `${manager.id}`,
      type: 'membership',
      data: {
        expanded: true,
        membership: manager,
        dataExpanded: showData,
        path: `${manager.id}/`,
      },
      style: {
        height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    });
  }

  directReports.forEach((membership: Membership) => {
    edges.push({
      id: `${rootMembership.id}:${membership.id}`,
      target: membership.id,
      source: rootMembership.id,
      ...edgeDefaultsUp,
    });

    // const expanded = mapState['expandedPlans'] && mapState['expandedPlans'][plan.id];

    nodes.push({
      id: `${membership.id}`,
      type: 'membership',
      data: {
        expanded: false,
        membership,
        dataExpanded: showData,
        path: `${membership.id}/`,
      },
      style: {
        height,
        maxHeight: height,
        width,
        maxWidth: width,
      },
      ...nodeDefaults,
    });
  });

  const fullScreenClass = fullScreenEnabled ? 'full-screen' : '';

  const isLoading = isLoadingDirectReports || isLoadingManager;

  return (
    <Container>
      {upgradeRequired && (
        <>
          <Mask />
          <UpgradeModal>
            <UpgradeRequired />
          </UpgradeModal>
        </>
      )}
      <Content className={fullScreenClass}>
        <HeaderContainer>
          <span className="title">Org Minimap</span>
          <div className="actions">
            <KoalaButton onClick={() => setShowData(!showData)} appearance="secondary" size="small">
              {showData ? t('shared.hideData') : t('shared.showData')}
            </KoalaButton>
            <KoalaButton onClick={() => setFullScreenEnabled(!fullScreenEnabled)} appearance="secondary" size="small">
              {fullScreenEnabled ? t('shared.exitFullScreen') : t('shared.enterFullScreen')}
            </KoalaButton>
          </div>
        </HeaderContainer>
        {hasFetchedReportsData && hasFetchedManagerData && !isLoading && (
          <ReactFlowProvider>
            <Map initialEdges={edges} initialNodes={nodes} fullScreenEnabled={fullScreenEnabled} showData={showData} />
          </ReactFlowProvider>
        )}
      </Content>
    </Container>
  );
}

export default PeopleMinimap;
