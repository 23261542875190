import React, { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';
import styled from 'styled-components';
import theme from 'theme';
import Profile from 'components/PeopleMinimap/Profile';
import queryKeys from 'config/queryKeys';
import { useQuery } from 'react-query';
import * as remoteApi from 'api/remote';
import { Membership, Workspace } from 'types';
import KoalaButton from 'koala/components/Button';
import KoalaIcon from 'koala/components/Icons';
import { useTranslation } from 'react-i18next';
import { edgeDefaultsUp, nodeDefaults } from 'utils/mapUtils';
import { CustomTermKey, translate } from 'utils/customTermUtils';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ActiveOutcomesList from 'components/PeopleMinimap/ActiveOutcomesList';
import ActiveInitiativesList from 'components/PeopleMinimap/ActiveInitiativesList';
import { setGlobalModalContent } from 'state/actions/globalUIActions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const PlanContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.blockBorder};
  border-radius: 4px;
  background-color: ${theme.colors.N0};
  display: flex;
  flex-direction: column;
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  width: 350px;
  max-height: 500px;
`;

const CustomHandle = styled(Handle)`
  background: transparent;
  border: none;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x2};
  max-height: 20rem;
  overflow: auto;

  label {
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const ExpandButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  padding: ${theme.spacing.x1};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.N3};
  }
  .expand-button {
    transform: rotate(-90deg);
    &.show-data {
      transform: rotate(0deg);
    }
  }
`;

function MembershipNode(node: NodeProps) {
  const { data } = node;
  const dispatch = useDispatch();
  const { membership, dataExpanded, expanded, toggleNodeExpand, toggleDataExpand } = data;
  const { t } = useTranslation();
  const currentWorkspace: Workspace = useSelector((state: any) => state.session.currentWorkspace, shallowEqual);
  const [directReports, setDirectReports] = useState<Membership[]>([]);
  const direct_reportsQueryKey = [queryKeys.reporting, 'direct_reports', membership.id];
  // We use addNodes and addEdges to add to the flow graph state
  const { addNodes, addEdges } = useReactFlow();

  useEffect(() => {
    const newNode = {
      id: `add-report-${membership.id}`,
      type: 'addReport',
      data: {
        membership: membership,
      },
      ...nodeDefaults,
    };
    const newEdge = {
      id: `${membership.id}:add-report-${membership.id}`,
      target: `add-report-${membership.id}`,
      source: membership.id,
      ...edgeDefaultsUp,
    };
    addNodes(newNode);
    addEdges(newEdge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newNodesToAdd = directReports.map((report) => {
      return {
        id: `${report.id}`,
        type: 'membership',
        data: {
          expanded: false,
          dataExpanded: false,
          membership: report,
          path: `${report.id}/`,
          toggleNodeExpand: data.toggleNodeExpand,
          toggleDataExpand: data.toggleDataExpand,
        },
        ...nodeDefaults,
      };
    });
    const newEdgesToAdd = directReports.map((report) => {
      return {
        id: `${membership.id}:${report.id}`,
        target: report.id,
        source: membership.id,
        ...edgeDefaultsUp,
      };
    });
    addNodes(newNodesToAdd);
    addEdges(newEdgesToAdd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directReports]);

  // get direct_reports
  const { isLoading: isLoadingDirectReports } = useQuery(
    direct_reportsQueryKey,
    remoteApi.fetchMembershipDirectReports,
    {
      staleTime: 0,
      onSuccess: (response: any) => {
        const reports: Membership[] = response.data;
        setDirectReports(reports);
      },
    },
  );

  const toggleNodeExpanded = (event: React.MouseEvent<HTMLDivElement>) => {
    setDirectReports((directReports) => (directReports ? [...directReports] : []));

    toggleNodeExpand(event, node);
  };

  const handleExpandDataClick = (event: React.MouseEvent<HTMLDivElement>) => {
    toggleDataExpand(event, node);
  };

  const addDirectReport = () => {
    dispatch(setGlobalModalContent(`people:${membership.id}:edit.reporting`));
  };

  // Only show the expand button if there are reports and the node is not expanded
  const hasDirectReports = directReports.length > 0 && !isLoadingDirectReports;
  const expandedDataClassName = dataExpanded ? 'show-data' : '';

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <CustomHandle position={Position.Top} type="target" id="in" key="in" isConnectableStart={false} />
      <ComponentsContainer>
        <PlanContainer className={`${expandedDataClassName}`}>
          <Profile membership={membership} />
          <ExpandButton onClick={handleExpandDataClick}>
            <KoalaIcon className={`${expandedDataClassName} expand-button`} iconName="downChevron" iconSize="small" />
            {dataExpanded && <small>{t('components.wholeOrgMinimap.hideContent')}</small>}
            {!dataExpanded && <small>{t('components.wholeOrgMinimap.showContent')}</small>}
          </ExpandButton>
          {dataExpanded && (
            <Content className={`nowheel`}>
              <label>{translate(currentWorkspace, CustomTermKey.OUTCOME, 2)}</label>
              <ActiveOutcomesList membership={membership} />
              <label>{translate(currentWorkspace, CustomTermKey.INITIATIVE, 2)}</label>
              <ActiveInitiativesList membership={membership} showContributingInitiatives={false} />
            </Content>
          )}
        </PlanContainer>
        {hasDirectReports && !expanded && (
          <div>
            <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
              <KoalaIcon iconName="downChevron" style={{ transform: 'rotate(-90deg)' }} />
              {t('components.wholeOrgMinimap.expand')}
            </KoalaButton>
          </div>
        )}
        {hasDirectReports && expanded && (
          <div>
            <KoalaButton onClick={toggleNodeExpanded} appearance="secondary">
              <KoalaIcon iconName="minus" />
              {t('components.wholeOrgMinimap.collapse')}
            </KoalaButton>
          </div>
        )}
        {!hasDirectReports && (
          <div>
            <KoalaButton onClick={addDirectReport} appearance="secondary">
              <KoalaIcon iconName="plus" />
              {t('components.wholeOrgMinimap.addDirectReports')}
            </KoalaButton>
          </div>
        )}
      </ComponentsContainer>
      <CustomHandle type="source" position={Position.Bottom} key="out" id="out" isConnectableStart={false} />
    </Container>
  );
}

export default memo(MembershipNode);
